.container {
	font-family: var(--common-font);
}

.inputContainer > div {
	padding-right: 15px;
}

.container label {
	font: normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.container h1 {
	font: normal normal 22px/26px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.container h2 {
	font: normal normal 18px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.container p {
	font: normal normal 10px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #aca9a9;
	margin-bottom: 5px;
	font-style: italic;
}

.textArea > div > textarea {
	background: #ffffff 0% 0% no-repeat padding-box !important;
	border: 1px solid #dadce0 !important;
	border-radius: 4px;
	height: 131px;
}

.headerContainer {
	text-align: left;
	border-radius: 0.25rem;
	padding-left: 25px;
}

.modal {
	padding: 1vw;
	border-radius: 0.25rem;
	padding-left: 25px;
	padding-top: 30px;
}

.vspaced > * {
	margin-bottom: 0.5vh;
}

.sectionLabel {
	font-family: var(--common-font);
	font-weight: bold;
	font-size: 1vw;
}

.footer {
	display: flex;
	justify-content: flex-end;
	padding: 0.1vmax;
	position: sticky;
	bottom: 0;
}

.footer .btn {
	min-width: 5vw;
}

.datatable thead tr {
	height: 40px;
}

.datatable thead tr th:first-child {
	padding-left: 0;
}

.datatable thead tr th {
	font-family: var(--common-font);
	vertical-align: bottom;
	background-color: transparent;
}

.datatable tr td {
	color: #3b3b3b;
	padding: 10px 8px;
	border-top: 1px solid #efefef;
	vertical-align: top;
	font: normal normal 12px/16px Poppins-Regular;
}

.datatable tbody tr td[data-label='Date & Time'],
.datatable tbody tr td[data-label='Old Values'],
.datatable tbody tr td[data-label='New Values'] {
	word-break: break-word;
	white-space: normal;
}

.datatable tr td:first-child {
	padding-left: 0 !important;
}

.datatable tbody tr td[data-label='Old Values'],
.datatable tbody tr td[data-label='New Values'] {
	min-width: 140px;
	max-width: 150px;
}

.datatable tbody tr td[data-label='Date & Time'] {
	min-width: 100px;
	max-width: 150px;
}

.datatable tr td:last {
	padding: 10px 24px 10px 8px;
}

.datatable tr:nth-child(even) td {
	background-color: #f3faff !important;
}

.datatableHeader {
	font-family: var(--common-font);
	height: 20px;
	color: #3b3b3b;
	padding: 20px 0px;
	vertical-align: top;
	font-size: 14px;
	font-weight: bold;
}

.header {
	font: bold normal 11px/20px Poppins-Regular;
	display: inline-block;
	width: 125px;
}
.headerItems {
	font: normal normal 11px/20px Poppins-Regular;
	display: inline-block;
	width: 135px;
}

.headerWallet {
	font: bold normal 11px/20px Poppins-Regular;
	display: inline;
	width: 115px;
	padding-right: 15px;
}

.headerRightId {
	font: bold normal 12px/15px Poppins-Regular;
	display: inline-block;
	width: 165px;
	text-align: right;
	color: #4898cf;
}

.headerRight {
	font: normal normal 20px/20px Poppins-Regular;
	display: inline-block;
	width: 115px;
	text-align: right;
}

.headerH1 {
	display: inline;
	padding-top: 20px;
	font: normal normal 20px/20px Poppins-Regular;
}

.headerLabel {
	padding-top: 10px;
	padding-left: 10px;
	font: normal normal 11px/20px Poppins-Regular;
}
.colon {
	padding-left: 30px;
	padding-right: 30px;
}

.buttonStatus {
	height: 20px;
	background-color: orange;
	border-radius: 10px;
	font-size: 14px;
}

.statusActive:disabled,
.statusDeactivated:disabled,
.statusInactive:disabled {
	height: 22px;
	color: white;
	border-radius: 10px;
	font-size: 14px;
	float: right;
}
.statusActive:disabled {
	background-color: green;
	margin-right: 15px;
}

.statusDeactivated:disabled {
	background-color: gray;
}

.statusInactive:disabled {
	background-color: gray;
	margin-right: 15px;
}

.verticalScroll {
	overflow-y: auto;
	overflow-x: hidden;
	height: 1000px;
	width: 100%;
}

.topRadioChoices {
	float: right;
	font: normal 11px/14px Poppins-Regular;
}

.editButton svg {
	margin-right: 0.5vw;
	fill: var(--orange);
	width: 1vmax;
	height: 1vmax;
	border-width: 0px;
	color: blue;
}

.editButton:disabled svg {
	fill: #c3c3c3;
}

.walletAdjustmentErrorCloseBtn {
	min-width: 40%;
}

.uploadAttachmentAdj [class*='slds-file-selector'] {
	text-align: center !important;
}

.container input[readonly] {
	cursor: not-allowed;
}

.walletRepUploadFieldContainer button {
	margin-top: 24px !important
}