.datePicker {
	width: 100%;
}

.error {
	color: #d93025;
	margin-top: 0.5em;
}

.channelRow {
	cursor: pointer;
}

.channelLabel,
.selectLabel {
	font-size: var(--input-label-font-size);
}

.filterContainer button {
	white-space: nowrap;
	overflow: hidden;
}

.partnerNameModalContainer {
	width: 539px;
}

.partnerNameModalContent {
	max-height: 100vh;
}

.transactionNoFilter :global(.lookupPrefix__control) {
	padding-left: 0.4vw !important;
}

.transactionNoLabel {
	font-size: 11px !important;
	font-family: var(--common-font);
}

.transactionNoContainer {
	margin-top: 1vh;
}

.transNo div[class*='MenuList'],
.transNo div[class*='menu-list'] {
	max-height: 43vh !important;
}

.transNo div[class*='lookupPrefix__option'] {
	font-size: 0.68vw !important;
}

.transactionNoModalContainer div[class*='Table_paginationContainer'] {
	color: #707070;
}

.transactionNoModalContainer div[class*='channelRow'] {
	font-size: 12px !important;
	padding-left: 14px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.transactionNoModalContainer div[class*='channelRow']:hover {
	color: #f26122;
}
