.label {
	margin-bottom: 1vh;
	display: flex;
	font-size: 11px;
	font-family: var(--common-font);
}

.required::after {
	color: #d93025;
	content: '*';
	margin-left: 0.1vw;
}

.optional {
	margin-left: 0.2vw;
	color: #8b9095;
}
