.paperContainer {
	padding: 1.5vmax;
	border-radius: 20px;
}

.title {
	color: var(--orange);
	font-size: 1.2vw;
	font-family: var(--common-font);
}

.innerContainer {
	margin-top: 3vh;
	border: 2px solid var(--orange);
	padding: 0.8vmax;
	padding-bottom: 0.5vmax;
	border-radius: 10px;
	font-family: var(--common-font);
	font-size: 0.7vw;
}

.filterLabel {
	padding-left: 0;
}
