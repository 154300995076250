.btn {
	font-family: var(--common-font);
	font-size: var(--input-label-font-size) !important;
	max-height: var(--input-min-height);
	min-height: var(--input-min-height);
	height: 100%;
}

.fullWidth {
	width: 100%;
}
