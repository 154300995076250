.searchBar {
	width: 100%;
}

.downloadButton {
	height: 100%;
}

.statusApproved {
	color: #14bb02;
}
.statusRejected {
	color: #FF0000;
}
.statusPending {
	color: #FFA500;
}
.statusExpired {
	color: #8b9095;
}

.innerButtons {
	padding-top: 1.9em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}
.checkIcon {
	fill: #14bb02;
}

.crossIcon,
.crossIcon:focus {
	color: var(--orange);
}

.crossIcon:hover {
	color: var(--darkerOrange);
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 1em;
	font-size: 0.7vw;
	color: #3e3f42;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.serviceTypeContainer {
	min-width: 20%;
}

.datePickerContainer {
	min-width: 15%;
}

.clearFilterBtnContainer {
	min-width: 60%;
}

.retryBtn {
	max-width: 50%;
	font-size: 2px;
}

.actionCell button {
	width: min(1vmax, 20px);
	height: min(1vmax, 20px);
}

.actionHeader {
	text-align: center;
	width: 100%;
}

.voidId {
	word-break: break-all;
}
