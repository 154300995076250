.container {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background-color: black;
	opacity: 0.8;
	z-index: 9000;
}

.progressContainer {
	left: 50%;
	top: 50%;
	position: fixed;
	overflow-y: hidden;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.progress {
	width: 4vmax;
}

.progress text {
	transform: translate(-1.3rem, 0.4rem);
}

.message {
	font-size: 0.8vw;
	font-family: var(--common-font);
	margin-bottom: 2vh;
	color: white;
}
