.modal {
	width: 30% !important;
	min-width: 0 !important;
}

.footer {
	background-color: #ffffff;
	padding: 0 10px 0 0;
}

.headerText {
	font-family: var(--common-font);
	font-size: 1vw;
	font-weight: bold;
	letter-spacing: 0px;
	color: #1e1e1e;
}

.bodyText {
	font-family: var(--common-font);
	font-size: 0.9vw;
	letter-spacing: 0;
	color: #3e3f42;
}

.actionBtn {
	min-width: 30%;
}
