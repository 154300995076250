:root {
	/* colors */
	--blue: #0176c0;
	--black: #3f4040;
	--lightGray: #f6f5f7;
	--white: #ffffff;
	--gray: #626262;
	--orange: #f26122;
	--darkerOrange: #d14f17;
	--darkerBlack: #000000de;
	--paleOrange: #f2612261;
	--whiteOrange: #f6a07b;
	--dangerRed: #f81c2c;
	--dangerRedHover: #df0818;
	--lightBlue: #f3faff;

	/* fonts */
	--common-font: Poppins-Regular;
	--common-font-bold: Poppins-Medium;
	--common-font-light: Poppins-Light;
	--alt-font: Wavehaus-SemiBold;
	--alt-font-regular: Wavehaus-Book;

	--input-label-font-size: 0.8vw;
	--input-min-height: 4vh;
}

html {
	position: relative;
}

html,
body {
	background-color: #fff;
}

body {
	overflow-x: hidden !important;
}

html,
body,
div#root {
	height: 100%;
}

.commonText {
	font: normal normal 12px/18px Poppins-Regular;
	color: #3b3b3b;
}

[class*='slds-size--'],
[class*='slds-size_'] {
	-webkit-box-flex: inherit !important;
	-ms-flex: inherit !important;
	flex: inherit !important;
}

::-webkit-scrollbar {
	background: transparent;
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
	background-color: #989898;
	border-radius: 4px;
	width: 6px;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #a0a0a5;
}

.slds-datepicker tbody > tr > td.slds-is-selected > .slds-day {
	background: var(--orange) 0% 0% no-repeat padding-box !important;
}

.slds-datepicker tbody > tr > td:focus > .slds-day {
	box-shadow: var(--orange) 0 0 0 1px inset;
}

.slds-datepicker,
.slds-datepicker td {
	font-size: 0.6vw !important;
}

.slds-datepicker .slds-day {
	width: 1.5vmax !important;
	height: 1.5vmax !important;
	min-width: 1.5vmax !important;
	line-height: 1.5vmax !important;
}

[class*='slds-truncate'] {
	font-size: clamp(2px, 2.5vw, 12px) !important;
	font: var(--common-font);
}

/* For showing page numbers */
div[class*='Table_paginationContainer'] {
	font-size: clamp(2px, 2.5vw, 12px);
}

.slds-dropdown {
	z-index: 10000 !important;
}
#portal-root {
	z-index: 9000;
}
#portal-denied {
	z-index: 9001;
}

@font-face {
	font-family: Wavehaus-Book;
	src: url(assets/fonts/Wavehaus/Wavehaus-66Book.otf);
}

@font-face {
	font-family: Wavehaus-SemiBold;
	src: url(assets/fonts/Wavehaus/Wavehaus-95SemiBold.otf);
}

@font-face {
	font-family: Poppins-Light;
	src: url(assets/fonts/Poppins/Poppins-Light.ttf);
}

@font-face {
	font-family: Poppins-Regular;
	src: url(assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
	font-family: Poppins-Medium;
	src: url(assets/fonts/Poppins/Poppins-Medium.ttf);
}

.unavailable-menu-item {
	position: relative;
	pointer-events: none;
}

.unavailable-menu-item::after {
	right: 12px;
	top: 8px;
	content: 'unavailable';
	position: absolute;
	height: fit-content;
	color: #d5d6d8;
	font-style: italic;
}

.slds-modal__footer {
	background-color: #ffffff;
}

/* Temporary fix for iframe */
body > iframe {
	display: none;
}

.slds-input {
	padding: 0.3vmax;
	padding-left: 1vw;
	font-size: var(--input-label-font-size);
	min-height: 0;
	line-height: 3vh;
}

.slds-dropdown-trigger {
	display: block;
}

.slds-tooltip-trigger {
	margin: 0 5px;
}

.slds-tooltip-trigger .slds-button:focus,
.slds-tooltip-trigger .slds-button:hover,
.slds-tooltip-trigger .slds-button:active {
	color: var(--sds-c-button-text-color-active, #e1571c);
}

.slds-popover_tooltip {
	background-color: #8b9095;
	color: white;
	line-height: 1.2vw;
}

.sectionTitle {
	font-family: var(--common-font-bold);
	font-size: 1vw;
	margin-bottom: 2vh;
}

.sectionSubTitle {
	font-family: var(--common-font-bold);
	font-size: 0.85vw;
	margin-bottom: 1.85vh;
}

.slds-form-element__legend {
	float: none !important;
}

.slds-spinner:before {
	background-color: #f26122 !important;
}

.slds-spinner__dot-a:before {
	background-color: #f26122 !important;
}

.slds-spinner__dot-b:before {
	background-color: #f26122 !important;
}

.slds-spinner:after {
	background-color: #f26122 !important;
}

.slds-spinner__dot-a:after {
	background-color: #f26122 !important;
}

.slds-spinner__dot-b:after {
	background-color: #f26122 !important;
}

.noMarginTop {
	margin-top: 0 !important;
}

.noMarginBottom {
	margin-bottom: 0 !important;
}

.noPaddingLeft {
	padding-left: 0 !important;
}

.noPaddingRight {
	padding-right: 0 !important;
}

.zipCodeSelect .slds-dropdown-trigger .slds-button {
	color: #080707 !important;
}

.noIcon svg {
	display: none !important;
}
