.modal {
	font-family: var(--common-font);
}

.body {
	padding: 1vmax;
}

.headerContainer {
	border: none;
}

.header {
	display: flex;
	justify-content: space-between;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.header .title {
	font-size: 1.2vw;
}

.footer {
	border-top: 1px solid #0000001a;
	display: flex;
	justify-content: flex-end;
	padding: 0.8vmax;
}

.footer .btn {
	min-width: 5vw;
}

.logoSubtext {
	color: #8b9095;
}

.logoSubtextValue {
	color: black;
	font-weight: bold;
	margin-left: 0.4vw;
}

.fileInput {
	margin: 2vmax 0;
	display: flex;
}

.fileInput #disabled-input-id {
	background-color: white;
}

.hideClass {
	visibility: hidden;
}

.loading {
	animation: spin infinite linear 1000ms;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.fileInput :global(.slds-input-has-icon .slds-spinner_container *::before),
.fileInput :global(.slds-input-has-icon .slds-spinner_container *::after) {
	background-color: var(--orange);
}

.fileInput input {
	pointer-events: none;
}

.refreshIcon {
	pointer-events: all;
	cursor: pointer;
}
.refreshIcon > svg {
	fill: #8b9095;
}

.black {
	color: black;
}

.gray {
	color: #989898;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.successMessage {
	display: flex;
	height: 11vh;
	padding-top: 2vh;
}
