.tabs > ul {
	border: 0px;
	background-color: #ffffff;
	border-bottom: 2px solid #0176c0;
}

.tabMaxheightAndWidth {
	min-height: 550px;
}

.tabs > div {
	border: 0px;
	min-height: 550px;
	padding-left: 0;
	padding-right: 0;
}

.tabs > ul > li {
	background: #e9f5fd 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	margin-right: 15px;
	border-bottom: 1px solid #0176c0;
}

.tabs > ul > li > :global(a.slds-is-active) {
	border: 0px;
	color: #ffffff;
	background: #0176c0 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px !important;
}

.tabs > ul > li > a {
	text-decoration: none !important;
}

.tabs > ul > li > :global(a.slds-tabs_scoped__link:hover) {
	border: 0px;
}
