.container {
	font-family: var(--common-font);
}

.header .leftPanel,
.header .rightPanel {
	flex-direction: column;
}

.footer .leftPanel,
.footer .rightPanel {
	flex-direction: row;
}

.leftPanel {
	display: flex;
	align-items: center;
	justify-content: left;
}

.rightPanel {
	display: flex;
	align-items: flex-end;
	justify-content: right;
}

.header {
	margin-bottom: 2vh;
	display: flex;
	justify-content: space-between;
}

.title {
	text-align: left;
	font-family: var(--common-font-bold);
	font-size: 1.2vw;
	width: 100%;
	margin-bottom: 2vh;
}

.status {
	display: flex;
	align-items: center;
}

.statusLabel {
	font: normal 0.8vw Poppins-Regular;
}

.statusField {
	margin-left: 10px;
	width: 125px;
}

.edit {
	margin-bottom: 2vh;
}

.linkBtn {
	border: none !important;
	color: orange !important;
	font: bold 12px Poppins-Regular !important;
	background: none !important;
}

.edit svg {
	margin-right: 0.5vw;
	fill: var(--orange);
	width: 1vmax;
	height: 1vmax;
	border-width: 0px;
	color: blue;
}

.edit:disabled svg {
	fill: #c3c3c3;
}

.body {
	height: 500px;
}

.hr {
	/* margin-top: 10px !important;
	margin-bottom: 10px !important; */
}

.footer {
	margin-top: 2vh;
	display: flex;
	justify-content: space-between;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.dates {
	text-align: right;
	margin-right: 16px;
}

.dateText {
	display: block;
	color: #8b9095;
	font-size: 0.8vw;
}

.submitRemarks {
	resize: none;
	height: 100px;
}

.submitRemarksSubLabel {
	font-size: 11px;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
	
}

.updateStatus {
	justify-content: left;
}

.updateStatusBody1 {
	text-align: center;
	margin-bottom: 20px;
}

.updateStatusBody2 {
	text-align: left;
}

.updateStatusRemarks {
	resize: none;
	height: 100px;
}

.updateStatusSubLabel {
	font-size: 11px;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
	text-align: left;
}

.approveHeading {
	top: 250px;
	left: 602px;
	font-size: 14px;
	font-weight: 600;
	font-stretch: condensed;
}