.paperContainer {
	padding: 0.5vmax;
	border-radius: 10px;
	margin-top: 41px;
}

.title {
	color: var(--orange);
	font-size: 1.2vw;
	font: normal 600 24px/24px var(--alt-font);
	letter-spacing: 0.74px;
	padding-block: 10px;
	margin-top: 5px;
}

.innerContainer {
	margin-top: 3vh;
	border: 2px solid var(--orange);
	padding: 0.8vmax;
	padding-bottom: 0.5vmax;
	border-radius: 10px;
	font-family: var(--common-font);
	font-size: 0.7vw;
}

.filterLabel {
	padding-left: 0;
}
