.lookupField > :global(div.lookupPrefix__control) {
	min-height: 0;
	font-size: var(--input-label-font-size);
	font-family: var(--common-font-light);
}

.lookupField
	:global(.lookupPrefix__indicator.lookupPrefix__dropdown-indicator) {
	padding: 0.3vmax 0.5vmax !important;
}

.lookupField :global(.lookupPrefix__dropdown-indicator) svg {
	width: 0.8vmax;
	height: 0.8vmax;
}

.lookupField :global(.lookupPrefix__value-container) {
	padding: 1px 8px;
}

.lookupField > :global(div.lookupPrefix__control--is-focused) {
	border-color: var(--sds-c-input-color-border-focus, #1b96ff);
	background-color: var(
		--sds-c-input-color-background-focus,
		var(--sds-c-input-color-background, #fff)
	);
	color: var(--sds-c-input-text-color-focus);
	box-shadow: var(--sds-c-input-shadow-focus, 0 0 3px #0176d3);
	outline: 0 !important;
}

.lookupField
	> :global(div.lookupPrefix__control)
	> :global(div.lookupPrefix__indicators)
	> :global(span.lookupPrefix__indicator-separator) {
	align-self: stretch;
	background-color: transparent;
	margin-bottom: 8px;
	margin-top: 8px;
	width: 1px;
}

.lookupField
	> :global(div.lookupPrefix__menu)
	> :global(div.lookupPrefix__menu-list)
	> :global(div.lookupPrefix__option) {
	font-size: var(--input-label-font-size);
	font-family: var(--common-font);
	letter-spacing: 0.11px;
	color: #3b3b3b;
}

.lookupField
	> :global(div.lookupPrefix__menu)
	> :global(div.lookupPrefix__menu-list)
	> :global(div.lookupPrefix__option:hover) {
	color: #f26122;
}

.seeMore {
	display: flex;
	justify-content: center;
	padding: 5px;
}

.seeMore a {
	font-size: var(--input-label-font-size);
	font-family: var(--common-font);
	letter-spacing: 0.11px;
	color: #0176c0;
}

.manualError .lookupField > :global(div.lookupPrefix__control) {
	border-color: #ea001e;
	-webkit-box-shadow: #ea001e 0 0 0 1px inset;
	box-shadow: #ea001e 0 0 0 1px inset;
	background-color: var(--sds-c-input-color-background, #fff);
	color: var(--sds-c-input-text-color);
	background-clip: padding-box;
}
