.addButton svg {
	margin-right: 0.5vw;
	fill: var(--blue);
	width: 1vmax;
	height: 1vmax;
}

.addButton:disabled svg {
	fill: #c3c3c3;
}

.confirmTextName {
	font-weight: bold;
}

.addressErrorCloseBtn {
	min-width: 40%;
}
.successText{
	font-size: 20px;
	font-weight: bold;
}

.successModalText {
	font-size: 18px;
	margin-top: 1vh;
	margin-bottom: 2vh;
}
