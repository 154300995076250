.modal {
	width: 35vw;
	align-self: center;
}

.container {
	padding: 1em;
}

.errorIcon {
	fill: red;
	width: 4vw;
}

.iconContainer {
	display: flex;
	justify-content: center;
}

.modalBody {
	font-family: var(--common-font);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin: 1em 0;
}

.modalActions > div {
	display: flex;
	justify-content: center;
}

.header button {
	display: none;
}

.errorText {
	font-size: 0.8vw;
	font-weight: bold;
	margin-top: 1vh;
	margin-bottom: 2vh;
}

.errorSubtext {
	width: 70%;
	font-size: 0.7vw;
	margin-bottom: 2vh;
}

.title {
	font-size: 1vw;
	margin: 1.2vw 0;
}

.closeButton {
	display: flex;
	float: right;
}

.closeButton:hover {
	cursor: pointer;
}

.noPreview {
	width: 100%;
	height: 60vh;
	background-color: #dadce0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 12px;
}
.download:hover {
	cursor: pointer;
}
.download {
	height: 20px;
	padding-left: 15px;
}
.retryButton {
	border: 1px solid var(--unnamed-color-f26122);
	border: 1px solid #f26122;
	border-radius: 4px;
	opacity: 1;
}
